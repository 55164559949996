import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { PaymentIntent } from 'src/api/types';
import { BannerSettings } from 'src/components/molecule/Banner';
import useSelectedLevel from 'src/hooks/useSelectedLevel';

import { trackEvent } from 'src/services/tracker';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { Translate } from 'src/components/Translate';
import { useAuthContext } from 'src/components/AuthProvider';
import { Banner } from 'src/components/molecule';
import { PaymentMethod } from 'src/components/organism';
import { CheckoutFormStripe } from 'src/components/CheckoutFormStripe';
import useMembership from 'src/api/useMembership';
import useActiveProduct from 'src/api/useActiveProduct';
import useAddOrder from 'src/api/useAddOrder';
import useDefaultPaymentMethod from 'src/api/useDefaultPaymentMethod';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import LoadingContainer from 'src/components/LoadingContainer';
import useRedirect from 'src/hooks/useRedirect';
import { SimpleFooter } from 'src/features/footer';

const UpgradeMembership: React.FC<PageProps> = ({ location }) => {
  const [level] = useSelectedLevel(1);
  const {
    formState: { isSubmitted, isValid },
    handleSubmit,
    register,
    errors,
    setError,
    ...useFormFields
  } = useForm({ mode: 'onChange' });
  const [banner, setBanner] = useState<BannerSettings>();
  const [showForm, setShowForm] = useState<boolean>();
  const [cartData, setCartData] = useState({});
  const [formData, setFormData] = useState({});
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
  const [confirmation, setConfirmation] = useState({});
  const [submitFormError, setSubmitFormError] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { data: membership, ...membershipQuery } = useMembership();

  const { product } = useActiveProduct();
  const { defaultPaymentMethod, defaultPaymentMethodLoading } = useDefaultPaymentMethod();
  // STRIPE (default) / LOADING
  const paymentProvider = defaultPaymentMethodLoading ? 'LOADING' : defaultPaymentMethod?.provider || 'STRIPE';

  const addOrder = useAddOrder({
    moneyPlatformData: null,
    order: cartData,
    paymentProvider,
    setPaymentIntent,
    confirmation,
    setSubmitFormError,
    setError,
    omniId: null,
  });

  const i18n = {
    breadcrumb: 'breadcrumbs.myAccount.text',
    heading: 'upgradePlan.heading',
    leadText: 'upgradeMembership.leadText',
    paymentTitle: 'upgradeMembership.paymentTitle',
    bannerError: 'checkout.payment.error.banner',
    confirmButton: 'updateMembership.confirmButton.text',
    cancelButton: 'updateMembership.cancelButton.text',
    bannerSuccess: 'upgradePlan.banner.success',
    genericMsg: 'generic.error.message',
  };

  const plan = product?.mileages[level - 1];

  const { state = {} } = location;
  const shouldRedirect = !state?.['nav'];

  useRedirect('/my-account/');

  useEffect(() => {
    if (isAuthenticated) membershipQuery.refetch();
  }, [isAuthenticated]);

  useEffect(() => {
    const unauthenticated = !loadingUser && !isAuthenticated;
    const hasMembership = membership?.hasMembership();

    if (unauthenticated || hasMembership === false) {
      // use === false here will exclude the undefined case
      membershipQuery.stopPolling();
      navigate('/checkout/', { replace: true });
      // Redirect member users that navigated directly
    } else if (shouldRedirect && hasMembership) {
      membershipQuery.stopPolling();
      navigate('/my-account/change-plan/');
    }
  }, [loadingUser, isAuthenticated, membership]);

  useEffect(() => {
    if (!membership?.data) return;
    setCartData({
      membershipId: membership.data.id,
      productId: product?.id,
      ...plan,
    });
  }, [membership?.data, membershipQuery.loading, product, plan]);

  useEffect(() => {
    // After placing the order and the membership matches with the new one created
    if (isSubmitted && membership?.data?.inventory?.id === plan?.id) {
      membershipQuery.stopPolling();
      navigate('/my-account/', {
        state: { msg: i18n.bannerSuccess },
      });
    }
  }, [membership?.data, isSubmitted]);

  useEffect(() => {
    if (submitFormError || errors.cardNumber || errors.cardExpiry || errors.cardCvc) {
      setBanner({
        display: true,
        message: i18n.bannerError,
        color: 'failure',
      });
    }
  }, [submitFormError, errors]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setFormData(data);
    setSubmitFormError(null);
    logTrackedEvent(trackEvent, {
      _tag_name: 'HDC Upgraded',
      price: plan.price.amount,
      membership_level: plan.name,
      old_membership: membership.data.name,
      price_change: plan.price.amount - membership.data.price,
    });
    await addOrder();
    setIsSubmitting(false);
  };

  // #hideFormForNewValidPM
  useEffect(() => {
    defaultPaymentMethod?.card ? setShowForm(false) : setShowForm(true);
  }, [defaultPaymentMethod]);

  // #trigger errors refresh when switching "Edit payment method" form
  useEffect(() => {
    setSubmitFormError(null);
    setBanner(null);
  }, [showForm]);

  return (
    <>
      {banner?.display && <Banner banner={banner} setBanner={setBanner} />}
      {isAuthenticated && !defaultPaymentMethodLoading && membership?.hasMembership() && plan && (
        <div className="container container_center page__update_membership">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Breadcrumbs originTransKey={i18n.breadcrumb} originPath={'/my-account/'} />
            <h2 className="text-display_2">
              <Translate resourceKey={i18n.heading} />
            </h2>
            <p className="text_2" data-cy="upgrade-lead-text">
              <Translate resourceKey={i18n.leadText} values={{ current: membership.data?.name, next: plan.name }} />
            </p>

            <div className="inset-s" />
            <legend className="text-heading_4">
              <Translate resourceKey={i18n.paymentTitle} />
            </legend>
            <div className="inset-s" />
            {defaultPaymentMethod?.card && !showForm && (
              <PaymentMethod
                card={defaultPaymentMethod.card}
                provider={defaultPaymentMethod.provider}
                onEdit={() => setShowForm(true)}
                error={errors['cardNumber' || 'cardExpiry' || 'cardCvc']?.message}
              />
            )}
            {paymentProvider === 'STRIPE' && (
              // DO NOT hide CheckoutFormStripe with !showForm, it's also responsible for other Stripe logic (like setConfirmation)
              <CheckoutFormStripe
                paymentIntent={paymentIntent}
                setConfirmation={setConfirmation}
                defaultCard={defaultPaymentMethod || {}}
                formData={formData}
                useFormFields={{ errors, setError, register, ...useFormFields }}
                hideForm={!showForm}
                submitPayment
              />
            )}
            {(isSubmitting || isSubmitted) && !submitFormError && <LoadingContainer height={40} />}

            {submitFormError && (
              <fieldset className="form-element__control has-error" data-cy="form-error">
                <div className="form-element__help">
                  <Translate resourceKey={submitFormError} />
                </div>
              </fieldset>
            )}
          </form>
        </div>
      )}
      {(defaultPaymentMethodLoading || !isAuthenticated || !membership?.hasMembership() || !plan) && (
        <LoadingContainer height={400} />
      )}
      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
};

export default UpgradeMembership;
